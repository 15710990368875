import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import '@/assets/css/style.css'
import { createMetaManager,plugin as metaPlugin } from 'vue-meta'
const metaManager = createMetaManager();

const app = createApp(App);
app.use(store).use(router).use(metaManager).use(metaPlugin);
router.isReady().then(() => app.mount("#app"));

//createApp(App).use(router).use(store).use(metaManager).mount('#app')
