import { createStore, createLogger} from 'vuex'
import AppData from '@/store/modules/appdata'
import authModule from '@/store/modules/auth'

import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
const debug = process.env.NODE_ENV !== 'production'
const ls = new SecureLS({ isCompression: false });
const _ = require('lodash');

export const initialStoreModules = {
    AppData,
    authModule

};
const store = createStore({
    modules: _.cloneDeep(initialStoreModules),
    mutations: {
        RESET_STATE (state) {
            _.forOwn(initialStoreModules, (value, key) => {
                if (key !== 'authModule') {
                    state[key] = _.cloneDeep(value.state);
                }
            });
        },
    },
    strict: debug,
    plugins: debug ? [
        createLogger(),
        createPersistedState({
            key: 'vuex',
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            },
            reducer (state) {
                // if (state.authModule.isUserLoggedIn === false) {
                //     //alert('sfsdfsd')
                //     state.authModule={}
                //     return state;
                //     //return {};
                // }

                return state;
            }
        })
  ] : [
    createPersistedState({
        key: 'vuex',
        storage: {
            getItem: key => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: key => ls.remove(key)
        },
        reducer (state) {
            // if (state.authModule.isUserLoggedIn === false) {
            //     //alert('sfsdfsd')
            //      state.authModule={}
            //         return state;
            // }

            return state;
        }
    })
 ]
});
export default store;

