const state = {
    authToken: {},
    user: {},
    membership: {},
    emailverificationstatus:1,
    formLoader: false,
    isUserLoggedIn: false,
    UserLoggedError: false,
    UserForgetpassError: false,
    forgotPasswordMessage:'',
    ErrorMessage:false,
    SuccessMessage:false,
    loadresetpassword:false,
    restpassworterror:false,
    userSaveLoader:false,
};

export default state;