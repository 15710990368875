<template>
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content} | RICOMAN Lighting` : `RICOMAN Lighting` }}</template>
    </metainfo>
  
    <Header v-if="!$route.meta.hideNavigation"/>
    <router-view /> 
    <Footer v-if="!$route.meta.hideNavigation"/>     
</template>

<script scoped>
 import {defineAsyncComponent} from 'vue';
// import Header from './components/Header.vue'
// import Footer from './components/Footer.vue'
import { mapActions} from 'vuex';

export default {
  name: 'App',

  components: {
    Header: defineAsyncComponent(() => import('./components/Header.vue')),
    Footer: defineAsyncComponent(() => import('./components/Footer.vue')),
  },

  methods:{
    ...mapActions({             
      GeneralInformation: 'AppData/GeneralInformation',
    }),
  },

  mounted() { 
    this.GeneralInformation();
  },   

  watch:{
    GeneralInformation(){
      this.GeneralInformation();
    }
  },

  metaInfo: {
    script: [
      { type: 'text/javascript',src:process.env.VUE_APP_BASE_URL+'js/trakingcode.js', async: true, defer: true }
    ],  
  } 
}
</script>

<style scoped>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
 .loadercss{ position: absolute; left:50%; top:50%; transform: translate(-50%, -50%);}
</style>
